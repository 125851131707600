// src/components/About.js
import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const About = () => {
  const { t } = useTranslation(); // Initialize translation

  return (
    <section id="about" className="relative h-screen bg-gray-900 text-white overflow-hidden border-b border-gray-800">
      {/* Abstract Geometric Shapes */}
      <motion.div 
        className="absolute top-10 left-10 w-32 h-32 bg-purple-600 rounded-full mix-blend-screen"
        animate={{ y: [0, -20, 0], rotate: [0, 45, 0] }}
        transition={{ duration: 10, repeat: Infinity }}
      ></motion.div>
      <motion.div 
        className="absolute top-1/3 right-20 w-40 h-40 bg-pink-600 skew-y-12 mix-blend-screen"
        animate={{ y: [0, -20, 0], rotate: [0, -45, 0] }}
        transition={{ duration: 15, repeat: Infinity }}
      ></motion.div>
      <motion.div 
        className="absolute bottom-20 left-1/3 w-24 h-24 bg-indigo-600 rounded-full mix-blend-screen"
        animate={{ y: [0, 20, 0], rotate: [0, 30, 0] }}
        transition={{ duration: 12, repeat: Infinity }}
      ></motion.div>

      {/* Content */}
      <div className="container mx-auto px-6 flex flex-col items-start justify-center h-full relative z-10">
        <motion.h2 
          className="text-6xl md:text-8xl font-extrabold leading-tight tracking-tight"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <span className="block transform rotate-3">{t('about.title1')}</span> {/* Translation for 'Welcome to' */}
          <span className="block text-purple-500 transform -rotate-3">{t('about.title2')}</span> {/* Translation for 'DYBNG' */}
        </motion.h2>
        <motion.p 
          className="text-lg md:text-2xl text-gray-300 max-w-2xl mt-8"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          {t('about.description')} {/* Translation for description */}
        </motion.p>
        <motion.div 
          className="mt-8 text-lg font-bold relative"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 0.4 }}
        >
          <motion.a 
            href="#contact"
            className="text-purple-500 relative inline-block py-2 px-4 overflow-hidden transition-all duration-300 group"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <span className="absolute inset-0 bg-purple-500 transform scale-x-0 origin-left group-hover:scale-x-100 transition-transform duration-300"></span>
            <span className="relative text-white group-hover:text-gray-900 transition-colors duration-300">{t('about.buttonText')}</span> {/* Translation for button text */}
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
};

export default About;
