// src/components/Contact.js
import React, { useState, useEffect } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const ContactForm = () => {
  const { t } = useTranslation(); // Initialize translation
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [token, setToken] = useState('');
  const [successMessage, setSuccessMessage] = useState('');  // New state for success message

  useEffect(() => {
    const handleReCaptchaVerify = async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
      const token = await executeRecaptcha('contact_form');
      setToken(token);
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send the token and form data to your backend server
      const formResponse = await fetch('/api/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formData, token }),
      });
      

      if (!formResponse.ok) {
        throw new Error('Failed to send the form data.');
      }

      // Show success message
      setSuccessMessage(t('contact.successMessage'));  // Translated success message
      setFormData({ name: '', email: '', message: '' });

      // Hide success message after 5 seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    } catch (error) {
      console.error('Error:', error);
      alert(t('contact.errorMessage'));  // Translated error message
    }
  };

  return (
    <section id="contact" className="relative py-20 bg-gray-900 text-white border-b border-gray-800 overflow-hidden">
      <div className="container mx-auto px-6 relative z-10">
        <h2 className="text-4xl font-extrabold text-center mb-12">
          {t('contact.title')}  {/* Translated title */}
        </h2>

        <div className="max-w-2xl mx-auto">
          {/* Success Message Box */}
          {successMessage && (
            <div className="mb-6 p-4 bg-green-500 text-white text-center rounded-lg shadow-lg">
              {successMessage}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6 bg-gray-800 p-8 rounded-lg shadow-lg">
            <div>
              <label htmlFor="name" className="block text-lg font-semibold text-gray-300">
                {t('contact.nameLabel')}  {/* Translated label for name */}
              </label>
              <input 
                type="text" 
                name="name" 
                id="name" 
                value={formData.name} 
                onChange={handleChange} 
                className="w-full px-4 py-3 mt-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                required 
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-lg font-semibold text-gray-300">
                {t('contact.emailLabel')}  {/* Translated label for email */}
              </label>
              <input 
                type="email" 
                name="email" 
                id="email" 
                value={formData.email} 
                onChange={handleChange} 
                className="w-full px-4 py-3 mt-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                required 
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-lg font-semibold text-gray-300">
                {t('contact.messageLabel')}  {/* Translated label for message */}
              </label>
              <textarea 
                name="message" 
                id="message" 
                value={formData.message} 
                onChange={handleChange} 
                className="w-full h-32 px-4 py-3 mt-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                required 
              />
            </div>
            <button 
              type="submit" 
              className="w-full py-3 bg-purple-600 text-white rounded-lg font-bold shadow-lg hover:bg-purple-700 transition-transform transform hover:scale-105"
            >
              {t('contact.submitButton')}  {/* Translated button text */}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

const Contact = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdGdi8qAAAAAA7uXvoBU8OHreCTMJeJqmbjZhtU">
      <ContactForm />
    </GoogleReCaptchaProvider>
  );
};

export default Contact;
