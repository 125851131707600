// src/components/Skills.js
import React from 'react';
import { FaReact, FaNodeJs, FaLaravel, FaPython, FaHtml5, FaCss3Alt, FaJsSquare, FaGitAlt } from 'react-icons/fa';
import { SiPhp, SiTailwindcss, SiCodeigniter, SiTypescript } from 'react-icons/si';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const skills = [
  { name: 'ReactJS', icon: <FaReact />, levelKey: 'expert', proficiency: 90 },
  { name: 'NodeJS', icon: <FaNodeJs />, levelKey: 'advanced', proficiency: 80 },
  { name: 'Laravel', icon: <FaLaravel />, levelKey: 'advanced', proficiency: 80 },
  { name: 'PHP', icon: <SiPhp />, levelKey: 'advanced', proficiency: 85 },
  { name: 'Python/Django', icon: <FaPython />, levelKey: 'expert', proficiency: 90 },
  { name: 'JavaScript', icon: <FaJsSquare />, levelKey: 'expert', proficiency: 95 },
  { name: 'TailwindCSS', icon: <SiTailwindcss />, levelKey: 'expert', proficiency: 90 },
  { name: 'Bootstrap', icon: <FaCss3Alt />, levelKey: 'advanced', proficiency: 80 },
  { name: 'HTML/CSS', icon: <FaHtml5 />, levelKey: 'expert', proficiency: 95 },
  { name: 'CodeIgniter', icon: <SiCodeigniter />, levelKey: 'intermediate', proficiency: 70 },
  { name: 'TypeScript', icon: <SiTypescript />, levelKey: 'advanced', proficiency: 85 },
  { name: 'Git', icon: <FaGitAlt />, levelKey: 'expert', proficiency: 90 }
];

const Skills = () => {
  const { t } = useTranslation(); // Initialize translation

  return (
    <section id="skills" className="relative py-20 bg-gray-900 text-white border-b border-gray-800">
      <div className="container mx-auto px-6 relative z-10">
        <h2 className="text-4xl font-extrabold text-center mb-12">{t('skills.title')}</h2> {/* Translate the title */}

        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-8">
          {skills.map((skill, index) => (
            <div 
              key={index} 
              className="p-6 bg-gray-800 rounded-lg shadow-lg flex flex-col items-center justify-center transform transition-all duration-300 hover:scale-105"
            >
              <div className="text-5xl text-purple-500 mb-4">
                {skill.icon}
              </div>
              <h3 className="text-xl font-semibold">{t(`skills.${skill.name}`)}</h3> {/* Translate the skill name */}
              <p className="text-gray-400 mt-2 mb-2">{t(`skills.levels.${skill.levelKey}`)}</p> {/* Translate skill level */}
              
              {/* Progress Bar */}
              <div className="w-full bg-gray-700 rounded-full h-2.5">
                <div 
                  className="bg-purple-500 h-2.5 rounded-full" 
                  style={{ width: `${skill.proficiency}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Parallax Background Effect */}
      <div className="absolute inset-0 z-0">
        <div className="absolute bottom-1/3 right-1/4 w-80 h-80 bg-purple-800 opacity-30 rounded-full filter blur-3xl"></div>
        <div className="absolute top-1/4 right-1/4 w-96 h-96 bg-indigo-800 opacity-20 rounded-full filter blur-2xl"></div>
      </div>
    </section>
  );
};

export default Skills;
