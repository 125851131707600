// src/i18n/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const savedLanguage = localStorage.getItem('language') || 'en'; // Default to English if no language is saved

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        // Navbar translations
        navbar: {
          about: 'About',
          skills: 'Skills',
          contact: 'Contact',
          clients: 'Clients',
        },
        // Hero section translations
        hero: {
          title: 'Cutting-Edge Web Solutions',
          subtitle: "Elevate your digital presence with DYBNG's expertise.",
          button: 'Get Started',
        },
        // About section translations
        about: {
          title1: 'Welcome to',
          title2: 'DYBNG',
          description:
            'At DYBNG, we craft bespoke digital solutions that elevate your business to the next level. From cutting-edge frontend designs to powerful backend architectures, we deliver excellence in every line of code.',
          buttonText: 'Discover Our Services',
        },
        // Skills section translations
        skills: {
          title: 'My Skills',
          ReactJS: 'ReactJS',
          NodeJS: 'NodeJS',
          Laravel: 'Laravel',
          PHP: 'PHP',
          'Python/Django': 'Python/Django',
          JavaScript: 'JavaScript',
          TailwindCSS: 'TailwindCSS',
          Bootstrap: 'Bootstrap',
          'HTML/CSS': 'HTML/CSS',
          CodeIgniter: 'CodeIgniter',
          TypeScript: 'TypeScript',
          Git: 'Git',
          levels: {
            expert: 'Expert',
            advanced: 'Advanced',
            intermediate: 'Intermediate',
          },
        },
        // Testimonials section translations
        testimonials: {
          title: 'What My Clients Say',
          markus: {
            quote:
              "I couldn't be happier with the work Ole Magnus Dybing did on my website. Not only is the design visually stunning and professional, but the site is also incredibly user-friendly and easy to navigate. Ole Magnus was a pleasure to work with – he was extremely knowledgeable, responsive, and always willing to go the extra mile to ensure I was satisfied. I highly recommend their services to anyone looking for a top-notch web developer.",
            clientName: 'Markus',
            companyName: 'NiteScape',
            clientTitle: 'CEO, NiteScape',
          },
        },
        // Contact section translations
        contact: {
          title: 'Get in Touch',
          nameLabel: 'Your Name',
          emailLabel: 'Your Email',
          messageLabel: 'Your Message',
          submitButton: 'Send Message',
          successMessage: 'Message sent successfully!',
          errorMessage: 'There was an issue submitting the form. Please try again later.',
        },
        // SEO translations
        seo: {
          hero: {
            title: 'DYBNG - Cutting-Edge Web Solutions',
            description: 'Welcome to DYBNG, where we craft cutting-edge web solutions.',
            keywords: 'web development, React, Node.js',
          },
          about: {
            title: 'About DYBNG',
            description: 'Learn more about DYBNG and how we elevate businesses with our web solutions.',
            keywords: 'about DYBNG, web development',
          },
          skills: {
            title: 'DYBNG - My Skills',
            description: 'Discover the skills we excel in, including React, Node.js, Laravel, and more.',
            keywords: 'React, Node.js, Laravel, web development skills',
          },
          testimonials: {
            title: 'What Clients Say',
            description: "See what clients have to say about DYBNG's web development services.",
            keywords: 'web development testimonials, DYBNG reviews',
          },
          contact: {
            title: 'Contact DYBNG',
            description: 'Get in touch with DYBNG to learn how we can help your business grow.',
            keywords: 'contact DYBNG, web development inquiry',
          },
        },
      },
    },
    no: {
      translation: {
        // Navbar translations
        navbar: {
          about: 'Om',
          skills: 'Ferdigheter',
          contact: 'Kontakt',
          clients: 'Klienter',
        },
        // Hero section translations
        hero: {
          title: 'Banebrytende Web-løsninger',
          subtitle: 'Hev din digitale tilstedeværelse med DYBNG sin ekspertise.',
          button: 'Kontakt Oss',
        },
        // About section translations
        about: {
          title1: 'Velkommen til',
          title2: 'DYBNG',
          description:
            'Hos DYBNG lager vi skreddersydde digitale løsninger som hever din virksomhet til neste nivå. Fra banebrytende frontend-designs til kraftige backend-arkitekturer, leverer vi kvalitet i hver eneste kodelinje.',
          buttonText: 'Oppdag Våre Tjenester',
        },
        // Skills section translations
        skills: {
          title: 'Mine Ferdigheter',
          ReactJS: 'ReactJS',
          NodeJS: 'NodeJS',
          Laravel: 'Laravel',
          PHP: 'PHP',
          'Python/Django': 'Python/Django',
          JavaScript: 'JavaScript',
          TailwindCSS: 'TailwindCSS',
          Bootstrap: 'Bootstrap',
          'HTML/CSS': 'HTML/CSS',
          CodeIgniter: 'CodeIgniter',
          TypeScript: 'TypeScript',
          Git: 'Git',
          levels: {
            expert: 'Ekspert',
            advanced: 'Avansert',
            intermediate: 'Middels',
          },
        },
        // Testimonials section translations
        testimonials: {
          title: 'Hva mine klienter sier',
          markus: {
            quote:
              'Jeg kunne ikke vært mer fornøyd med arbeidet Ole Magnus Dybing gjorde på nettsiden min. Ikke bare er designet visuelt imponerende og profesjonelt, men siden er også utrolig brukervennlig og enkel å navigere. Ole Magnus var en glede å jobbe med – han var svært kunnskapsrik, lydhør, og alltid villig til å gå en ekstra mil for å sikre at jeg var fornøyd. Jeg anbefaler tjenestene deres til alle som ser etter en førsteklasses webutvikler.',
            clientName: 'Markus',
            companyName: 'NiteScape',
            clientTitle: 'CEO, NiteScape',
          },
        },
        // Contact section translations
        contact: {
          title: 'Ta Kontakt',
          nameLabel: 'Ditt Navn',
          emailLabel: 'Din E-post',
          messageLabel: 'Din Melding',
          submitButton: 'Send Melding',
          successMessage: 'Meldingen ble sendt!',
          errorMessage: 'Det oppsto et problem med å sende meldingen. Vennligst prøv igjen senere.',
        },
        // SEO translations
        seo: {
          hero: {
            title: 'DYBNG - Banebrytende Web-løsninger',
            description: 'Velkommen til DYBNG, hvor vi lager banebrytende web-løsninger.',
            keywords: 'webutvikling, React, Node.js',
          },
          about: {
            title: 'Om DYBNG',
            description: 'Lær mer om DYBNG og hvordan vi hever bedrifter med våre web-løsninger.',
            keywords: 'om DYBNG, webutvikling',
          },
          skills: {
            title: 'Mine Ferdigheter',
            description: 'Oppdag ferdighetene vi mestrer, inkludert React, Node.js, Laravel og mer.',
            keywords: 'React, Node.js, Laravel, webutviklingsferdigheter',
          },
          testimonials: {
            title: 'Hva Klienter Sier',
            description: "Se hva klientene sier om DYBNG's webutviklingstjenester.",
            keywords: 'webutvikling testimonials, DYBNG anmeldelser',
          },
          contact: {
            title: 'Kontakt DYBNG',
            description: 'Ta kontakt med DYBNG for å lære hvordan vi kan hjelpe din bedrift å vokse.',
            keywords: 'kontakt DYBNG, webutviklingsforespørsel',
          },
        },
      },
    },
  },
  lng: savedLanguage,  // Use the saved language or default to 'en'
  fallbackLng: 'no',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
