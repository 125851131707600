// src/components/Testimonials.js
import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const testimonials = [
  {
    quoteKey: 'testimonials.markus.quote', // Translation key for the quote
    clientNameKey: 'testimonials.markus.clientName', // Translation key for client name
    companyNameKey: 'testimonials.markus.companyName', // Translation key for company name
    clientTitleKey: 'testimonials.markus.clientTitle', // Translation key for client title
    clientImage: '../assets/client1.png',
  },
  // Add more testimonials as needed with translation keys
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation(); // Initialize translation

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <section id="testimonials" className="py-20 bg-gray-900 text-white relative border-b border-gray-800">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-extrabold text-center mb-12">
          {t('testimonials.title')} {/* Translation for section title */}
        </h2>

        <div className="relative max-w-4xl mx-auto bg-gray-800 rounded-lg shadow-lg p-8">
          <div className="flex items-center justify-between">
            <button onClick={handlePrev} className="text-purple-500 hover:text-purple-700 transition-colors">
              <FaChevronLeft size={24} />
            </button>

            <div className="flex-grow mx-4">
              <div className="text-center">
                <p className="text-3xl md:text-3xl text-gray-300 mb-4">
                  {t(testimonials[currentIndex].companyNameKey)} {/* Translation for company name */}
                </p>
                <p className="text-xl md:text-2xl italic text-gray-300 mb-4">
                  "{t(testimonials[currentIndex].quoteKey)}" {/* Translation for quote */}
                </p>
                <h3 className="text-lg font-bold">
                  {t(testimonials[currentIndex].clientNameKey)} {/* Translation for client name */}
                </h3>
                <p className="text-sm text-gray-400">
                  {t(testimonials[currentIndex].clientTitleKey)} {/* Translation for client title */}
                </p>
              </div>
            </div>

            <button onClick={handleNext} className="text-purple-500 hover:text-purple-700 transition-colors">
              <FaChevronRight size={24} />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
