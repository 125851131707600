// src/components/Hero.js
import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const Hero = () => {
  const { t } = useTranslation(); // Initialize translation

  return (
    <section
      id="hero"
      className="relative h-screen flex items-center bg-gray-900 overflow-hidden border-b border-gray-800 sm:pt-76 lg:pt-0"
    >
      <div className="container mx-auto px-6 flex flex-col md:flex-row items-center justify-between">
        {/* Text Section */}
        <div className="text-white z-20 max-w-xl">
          <motion.h1
            className="text-5xl md:text-7xl font-extrabold leading-tight"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            {t('hero.title')} {/* Translate the hero title */}
          </motion.h1>
          <motion.p
            className="mt-6 text-lg md:text-2xl text-gray-400"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.3 }}
          >
            {t('hero.subtitle')} {/* Translate the hero subtitle */}
          </motion.p>
          <Link to="contact" smooth={true} duration={500}>
            <motion.button
              className="mt-8 px-8 py-4 bg-purple-700 text-white rounded-full font-bold text-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {t('hero.button', 'Get Started')} {/* Fallback to English if buttonText is missing */}
            </motion.button>
          </Link>
        </div>

        {/* Visual Section */}
        <div className="relative mt-12 md:mt-0 z-10">
          <div className="absolute inset-0  opacity-10 mix-blend-overlay"></div>
          <div className="relative">
            <img
              src={require("../assets/New_Project_2.webp")}
              alt="Abstract Tech"
              className="max-w-full h-auto rounded-lg"
              width="640"
              height="636"
            />
          </div>
        </div>
      </div>

      {/* Parallax Effect */}
      <div className="absolute inset-0 z-0">
        <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-purple-800 opacity-30 rounded-full filter blur-3xl"></div>
        <div className="absolute bottom-1/3 right-1/4 w-80 h-80 bg-indigo-800 opacity-30 rounded-full filter blur-2xl"></div>
      </div>
    </section>
  );
};

export default Hero;
