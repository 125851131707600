// src/App.js
import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import About from "./components/About";
import Skills from "./components/Skills";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./i18n/i18n";
import { Helmet } from "react-helmet";
import "semantic-ui-css/semantic.min.css";

function App() {
  const { t } = useTranslation();
  const [currentSection, setCurrentSection] = useState("hero"); // Set the initial section as 'hero'

  // Effect to track scroll position and update the current section
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      if (scrollPosition >= document.getElementById("contact").offsetTop) {
        setCurrentSection("contact");
      } else if (
        scrollPosition >= document.getElementById("testimonials").offsetTop
      ) {
        setCurrentSection("testimonials");
      } else if (
        scrollPosition >= document.getElementById("skills").offsetTop
      ) {
        setCurrentSection("skills");
      } else if (scrollPosition >= document.getElementById("about").offsetTop) {
        setCurrentSection("about");
      } else {
        setCurrentSection("hero");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      {/* Global and dynamic Helmet tags */}
      <Helmet>
        <title>{t(`seo.${currentSection}.title`)}</title>
        <meta
          name="description"
          content={t(`seo.${currentSection}.description`)}
        />
        <meta name="keywords" content={t(`seo.${currentSection}.keywords`)} />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://www.dybng.no" />
      </Helmet>

      {/* Main Components */}
      <Navbar />
      <Hero />
      <About />
      <Skills />
      <Testimonials />
      <Contact />
      <Footer />
    </I18nextProvider>
  );
}

export default App;
