// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { Flag, Dropdown } from 'semantic-ui-react';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  const languageOptions = [
    {
      key: 'en',
      text: 'EN',
      value: 'en',
      flag: 'gb',
      onClick: () => changeLanguage('en'),
    },
    {
      key: 'no',
      text: 'NO',
      value: 'no',
      flag: 'no',
      onClick: () => changeLanguage('no'),
    },
  ];

  return (
    <nav className="fixed top-0 left-0 w-full z-40 bg-gray-900 bg-opacity-80 shadow-lg">
      <div className="container mx-auto flex items-center justify-center py-2 relative">
        {/* Left side links */}
        <div className="hidden md:flex space-x-8 absolute left-0">
          <Link to="about" smooth={true} duration={500} className="group text-white text-xl cursor-pointer relative">
            {t('navbar.about')}
            <span className="absolute left-0 bottom-0 w-0 h-1 bg-purple-500 transition-all group-hover:w-full"></span>
          </Link>
          <Link to="skills" smooth={true} duration={500} className="group text-white text-xl cursor-pointer relative">
            {t('navbar.skills')}
            <span className="absolute left-0 bottom-0 w-0 h-1 bg-purple-500 transition-all group-hover:w-full"></span>
          </Link>
        </div>

        {/* Brand in the center */}
        <Link
          to="hero"
          smooth={true}
          duration={500}
          className="text-4xl font-bold text-white tracking-widest text-center cursor-pointer relative"
        >
          <span className="text-purple-500">D</span>YBNG
          <span className="absolute left-0 bottom-0 w-0 h-1 bg-purple-500 transition-all group-hover:w-full"></span>
        </Link>

        {/* Right side content */}
        <div className="flex items-center absolute right-0">
          {/* Right side links */}
          <div className="hidden md:flex space-x-8 items-center">
            <Link
              to="testimonials"
              smooth={true}
              duration={500}
              className="group text-white text-xl cursor-pointer relative"
            >
              {t('navbar.clients')}
              <span className="absolute left-0 bottom-0 w-0 h-1 bg-purple-500 transition-all group-hover:w-full"></span>
            </Link>
            <Link
              to="contact"
              smooth={true}
              duration={500}
              className="group text-white text-xl cursor-pointer relative"
            >
              {t('navbar.contact')}
              <span className="absolute left-0 bottom-0 w-0 h-1 bg-purple-500 transition-all group-hover:w-full"></span>
            </Link>
          </div>

          {/* Language Selector (desktop version, hidden on mobile) */}
          <div className="hidden md:flex items-center ml-8">
            <Dropdown
              className="text-white"
              options={languageOptions}
              trigger={
                <span>
                  <Flag name={i18n.language === 'no' ? 'no' : 'gb'} /> {i18n.language === 'no' ? 'NO' : 'EN'}
                </span>
              }
              pointing="top right"
              on="click" // Opens only on click
              style={{ color: 'white' }}
            />
          </div>
        </div>

        {/* Mobile Menu Button (Visible on Mobile) */}
        <div className="md:hidden flex items-center absolute right-8">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            <svg
              className="w-8 h-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
              ></path>
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden absolute top-0 left-0 w-full bg-gray-900 bg-opacity-90 text-white flex flex-col items-center py-6 space-y-6">
          <Link to="about" smooth={true} duration={500} className="text-2xl" onClick={toggleMenu}>
            {t('navbar.about')}
          </Link>
          <Link to="skills" smooth={true} duration={500} className="text-2xl" onClick={toggleMenu}>
            {t('navbar.skills')}
          </Link>
          <Link to="testimonials" smooth={true} duration={500} className="text-2xl" onClick={toggleMenu}>
            {t('navbar.clients')}
          </Link>
          <Link to="contact" smooth={true} duration={500} className="text-2xl" onClick={toggleMenu}>
            {t('navbar.contact')}
          </Link>

          {/* Language Selector for Mobile (hidden on desktop) */}
          <div className="flex md:hidden items-center">
            <Dropdown
              className="text-white"
              options={languageOptions}
              trigger={
                <span>
                  <Flag name={i18n.language === 'no' ? 'no' : 'gb'} /> {i18n.language === 'no' ? 'NO' : 'EN'}
                </span>
              }
              pointing="top right"
              on="click" // Opens only on click
              style={{ color: 'white' }}
            />
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
